import {Helmet} from 'react-helmet';
import React, {useRef} from "react";
import {useSnipcart} from "./snipcart/Hook";

export const Snipcart = () => {
    const snipcartDiv = useRef(null);
    const snipcartManager = useSnipcart();
    snipcartManager.setDivReference(snipcartDiv);
    return (<div ref={snipcartDiv}>
        <Helmet htmlAttributes={{lang: 'es'}}>
            <link rel="stylesheet" href="https://cdn.snipcart.com/themes/v3.0.13/default/snipcart.css"/>
            <script src="https://cdn.snipcart.com/themes/v3.0.13/default/snipcart.js"/>
        </Helmet>
        <div
            id="snipcart"
            data-currency="eur"
            data-api-key={process.env.REACT_APP_SNIPCART_KEY}
            data-config-add-product-behavior="none"
        >
        </div>
    </div>);
};
