import React, {useEffect, useState} from 'react';
import {createStyles, fade, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import logo from './logo.png';
import {Button} from "@material-ui/core";
import FarmaDrawer from "./FarmaDrawer";
import {CategoryLevel2} from "./model/CategoryLevel2";
import {useSanity} from "./sanity/Hook";
import {CategoryLevel1} from "./model/CategoryLevel1";
import {arrayOrEmpty} from "./instrumentation/Array";
import CartIcon from "./CartIcon";
import InternalLink from "./InternalLink";
import UserIcon from "./UserIcon";
import {useSnipcart} from "./snipcart/Hook";
import SearchInput from "./SearchInput";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        logo: {
            width: 180,
            paddingRight: 30
        },
        search: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25),
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(3),
                width: 'auto',
            },
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        //drawer list
        drawerList: {
            backgroundColor: theme.palette.background.paper,
            lineHeight: 20
        },
        listSection: {
            backgroundColor: 'inherit',
            lineHeight: 20
        },
        ul: {
            backgroundColor: 'inherit',
            padding: 0,
            lineHeight: 20
        },
        appBar: {
            zIndex: 999999,
        },
    }),
);

export default function MainMenu() {
    const sanity = useSanity();
    const snipcartManager = useSnipcart();
    const classes = useStyles();
    const [categoriesLevel1, setCategoriesLevel1] = useState([] as CategoryLevel1[]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [categoriesLevel2, setCategoriesLevel2] = useState([] as CategoryLevel2[]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    useEffect(() => {
        sanity.getCategoriesLevel1().then(categories => setCategoriesLevel1(categories))
    }, [sanity]);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const showSnipcart = () => {
        setDrawerOpen(false);
        snipcartManager.show();
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={handleMenuClose}>My account</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton
                    aria-label="Ver cesta"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                    className="snipcart-checkout"
                    onClick={() => showSnipcart()}
                >
                    <CartIcon/>
                </IconButton>
                <UserIcon onClick={() => setDrawerOpen(false)}/>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    let openDrawer = (category: CategoryLevel1) => {
        setCategoriesLevel2(arrayOrEmpty(category.subcategorias));
        setDrawerOpen(previousState => !previousState);
    };
    return (
        <>
            <div className={classes.grow}>
                <AppBar className={classes.appBar} position="fixed" elevation={0}>
                    <Toolbar>
                        <InternalLink to="/" onClick={() => setDrawerOpen(false)}> <img src={logo}
                                                                                        className={classes.logo}
                                                                                        alt="logo"/></InternalLink>
                        {(categoriesLevel1.map((category) =>
                            (<Button key={category.nombre}
                                     onClick={() => openDrawer(category)}>{category.nombre}</Button>)
                        ))}
                        <div className={classes.grow}/>
                        <div className={classes.search}>
                            <SearchInput onActivated={() => setDrawerOpen(false)}/>
                        </div>
                        <div className={classes.sectionDesktop}>
                            <IconButton
                                className="snipcart-checkout"
                                edge="end"
                                aria-label="ver cesta"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                color="secondary"
                                onClick={() => showSnipcart()}
                            >
                                <CartIcon/>
                            </IconButton>
                            <UserIcon onClick={() => setDrawerOpen(false)}/>
                        </div>
                        <div className={classes.sectionMobile}>
                            <IconButton
                                aria-label="show more"
                                aria-controls={mobileMenuId}
                                aria-haspopup="true"
                                onClick={handleMobileMenuOpen}
                                color="inherit"
                            >
                                <MoreIcon/>
                            </IconButton>
                        </div>
                    </Toolbar>
                </AppBar>
                {renderMobileMenu}
                {renderMenu}
            </div>
            <FarmaDrawer categoriesLevel2={categoriesLevel2} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen}/>
        </>
    );
}
