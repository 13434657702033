import FarmaStepper from "./FarmaStepper";
import FarmaCards from "./FarmaCards";
import React from "react";

const Home = () => (
    <>
        <FarmaStepper/>
        <FarmaCards/>
    </>
);

export default Home
