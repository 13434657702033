import {createStyles, makeStyles, useMediaQuery} from "@material-ui/core";
import {Theme} from "@material-ui/core/styles/createMuiTheme";
import React, {useEffect, useState} from "react";
import {LogoColors} from "./styles/Colors";
import OfferList from "./OfferList";
import {useSanity} from "./sanity/Hook";
import {TemporalOffer} from "./model/TemporalOffer";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        container: {
            position: "relative",
            textAlign: "center",
            fontFamily: 'Courier',
            color: LogoColors.darkGreen1,
        },
        image: {
            width: "100%",
        },
        topLeft: {
            position: "absolute",
            top: "8px",
            left: "16px"
        }
    })
);


const TemporalOffers = () => {
    const sanity = useSanity();
    const [temporalOffers, setTemporalOffers] = useState<TemporalOffer[]>([]);
    const classes = useStyles();
    const isTabletOrMobile = useMediaQuery('(max-width: 1024px)')

    useEffect(() => {
        sanity.getTemporalOffers().then(setTemporalOffers);
    }, [sanity])

    return (
        <>
            {temporalOffers.map( temporalOffer =>
                <div key={temporalOffer._id}>
                    <div className={classes.container}
                         style={{fontSize: isTabletOrMobile ? temporalOffer.tamanoTextoMovil : temporalOffer.tamanoTexto}}>
                        <img
                            src={isTabletOrMobile ? temporalOffer.urlImagenMovil : temporalOffer.urlImagen}
                            alt="Snow" className={classes.image}/>
                        <div className={classes.topLeft}>{temporalOffer.nombre}</div>
                    </div>
                    <OfferList title={temporalOffer.descripcion || temporalOffer.nombre || ''}
                               products={temporalOffer.productos}/>
                </div>)}
        </>

    );
}

export default TemporalOffers;
