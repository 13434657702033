const LogoColors = {
    red: "#9A1B1E",
    darkGreen1: "#0C3C1F",
    darkGreen2: "#0D532A",
    green: "#58BA47",
    yellowGreen: "#D2F395",
}
const lightGray = '#e4e4e4';
const gray = "#e4e4e4";
const darkGray = "#5f5b5b";
const white = "#ffffff";
const cream = "#ffffff";
export {
    white,
    lightGray,
    gray,
    darkGray,
    cream,
    LogoColors
}


