import {SanityApi, SearchResult} from "./SanityApi";
import {CategoryLevel1} from "../model/CategoryLevel1";
import {CategoryPath} from "../model/CategoryPath";
import {CategoryLevel3} from "../model/CategoryLevel3";
import {Product} from "../model/Product";
import {findCategoryPath} from "../instrumentation/Category";
import {Offer} from "../model/Offer";
import {CategoryLevel2} from "../model/CategoryLevel2";
import {TemporalOffer} from "../model/TemporalOffer";

export class CachedSanityApi implements SanityApi {

    private categoriesLevel1: CategoryLevel1[] | undefined;
    private categoriesLevel2: CategoryLevel2[] | undefined;
    private slugToCategoryPathMap: Map<string, CategoryPath | undefined>;
    private categoryLevelId3ToProductMap: Map<string, Product[]>;
    private idToProductMap: Map<string, Product | undefined>;
    private offers: Offer[] | undefined;
    private temporalOffers: TemporalOffer[] | undefined;

    constructor(private innerApi: SanityApi) {
        this.slugToCategoryPathMap = new Map<string, CategoryPath | undefined>();
        this.categoryLevelId3ToProductMap = new Map<string, Product[]>();
        this.idToProductMap = new Map<string, Product | undefined>();
    }

    getCategoriesLevel1(): Promise<CategoryLevel1[]> {
        const getAndCache = async () => {
            const categories = await this.innerApi.getCategoriesLevel1();
            this.categoriesLevel1 = categories;
            return categories;
        };

        return this.categoriesLevel1 ? Promise.resolve(this.categoriesLevel1) : getAndCache();
    }

    getCategoriesLevel2(): Promise<CategoryLevel2[]> {
        const getAndCache = async () => {
            const categories = await this.innerApi.getCategoriesLevel2();
            this.categoriesLevel2 = categories;
            return categories;
        };

        return this.categoriesLevel2 ? Promise.resolve(this.categoriesLevel2) : getAndCache();
    }

    getCategoryPath(slug: string): Promise<CategoryPath | undefined> {
        const getAndCache = async () => {
            const categoriesLevel1 = await this.getCategoriesLevel1();
            const path = findCategoryPath(categoriesLevel1, slug);
            this.slugToCategoryPathMap.set(slug, path);
            return path;
        };

        return this.slugToCategoryPathMap.has(slug) ?
            Promise.resolve(this.slugToCategoryPathMap.get(slug)) :
            getAndCache();
    }

    getProductsByCategoryLevel3(categoryLevel3: CategoryLevel3): Promise<Product[]> {
        const getAndCache = async ()  => {
            const products = await this.innerApi.getProductsByCategoryLevel3(categoryLevel3);
            this.categoryLevelId3ToProductMap.set(categoryLevel3._id,products);
            return products;
        };

        return this.categoryLevelId3ToProductMap.has(categoryLevel3._id) ?
            Promise.resolve(this.categoryLevelId3ToProductMap.get(categoryLevel3._id)!) :
            getAndCache();
    }

    searchProducts(searchTerm: string): Promise<SearchResult> {
        return this.innerApi.searchProducts(searchTerm);
    }

    getProduct(productId: string): Promise<Product | undefined> {
        const getAndCache = async ()  => {
            const product = await this.innerApi.getProduct(productId);
            this.idToProductMap.set(productId,product);
            return product;
        };

        return this.idToProductMap.has(productId) ?
            Promise.resolve(this.idToProductMap.get(productId)) :
            getAndCache();
    }

    getOffers(): Promise<Offer[]> {
        const getAndCache = async () => {
            const offers = await this.innerApi.getOffers();
            this.offers = offers;
            return offers;
        };

        return this.offers ? Promise.resolve(this.offers) : getAndCache();
    }

    getTemporalOffers(): Promise<TemporalOffer[]> {
        const getAndCache = async () => {
            const offers = await this.innerApi.getTemporalOffers();
            this.temporalOffers = offers;
            return offers;
        };

        return this.temporalOffers ? Promise.resolve(this.temporalOffers) : getAndCache();
    }
}
