import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";
import {useSnipcart} from "./snipcart/Hook";
import IconButton from "@material-ui/core/IconButton";

type UserIconProps = {
    onClick?: () => any
}
const UserIcon = ({onClick}: UserIconProps) => {
    const snipcartManager = useSnipcart();
    const onIconClick = () => {
        if(onClick) {
            onClick();
        }
        snipcartManager.show();
    };
    return <IconButton
        edge="end"
        aria-label="Entrar"
        aria-haspopup="true"
        color="secondary"
        className="snipcart-customer-signin"
    >
        <AccountCircleIcon onClick={onIconClick}/>
    </IconButton>
};

export default UserIcon;
