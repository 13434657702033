import React, {useEffect, useState} from "react";
import {useSanity} from "./sanity/Hook";
import {Product} from "./model/Product";
import * as H from "history";
import {extractProductId, slugifyProduct} from "./instrumentation/Slug";
import {priceToText} from "./instrumentation/Numeric";

type ProductDetailProps =  {
    location: H.Location<undefined>
}

const ProductDetail = ({location}: ProductDetailProps) => {
    const productId = extractProductId(location.pathname);
    const sanity = useSanity();
    const [product, setProduct] = useState<Product | undefined>(undefined);

    useEffect(() => {
        sanity.getProduct(productId).then(setProduct);
    }, [sanity, productId]);

    return product ? <div>
        <img src={product.imagen} alt={product.nombre}/>
        <h3>{product.nombre}</h3>
        <h4>{product.descripcion}</h4>
        <button
            style={{
                backgroundColor: "#212121",
                borderRadius: "5px",
                color: "#F5F5F5",
                fontWeight: "bold",
                paddingBottom: "15px",
                paddingTop: "15px",
                paddingRight: "35px",
                paddingLeft: "35px",
                fontSize: "24"
            }}
            id="buyButton"
            className='snipcart-add-item'
            data-item-id={product._id}
            data-item-price={(product.precioOferta || product.precio)?.toFixed(2)}
            data-item-image={product.imagen}
            data-item-name={product.nombre}
            data-item-description={product.descripcion}
            data-item-url={`https://cartagena121.netlify.app/productos/${slugifyProduct(product)}`}
            data-items-categories={product.categorias.map(c => c.nombre).join('|')}>
            {`Comprar por ${priceToText(product.precio)}`}
        </button>
    </div> : null
};

export default ProductDetail;
