import {LocationState, History} from "history";

export class RouteManager<S = LocationState> {
    private readonly _history: History<S>;

   constructor(history: History<S>) {
       this._history = history;
   }

   get history() {
       return this._history;
   }

    navigateTo(location: string) {
        this._history.push(location);
    }
}
