import {
    createStyles,
    GridList,
    GridListTile,
    GridListTileBar,
    Theme
} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";

export default function FarmaCards() {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden',
                backgroundColor: theme.palette.background.paper,
            },
            gridList: {
                flexWrap: 'nowrap',
                // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
                transform: 'translateZ(0)',
            },
            title: {
                color: theme.palette.primary.light,
            },
            titleBar: {
                background:
                    'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
            },
        }),
    );

    const classes = useStyles();

    const specialCategories = [
        {
            image: "https://images.unsplash.com/photo-1553649084-3e42773ff0e3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80",
            title: "LISTOS PARA EL VERANO"
        },
        {
            image: "https://images.unsplash.com/photo-1576035403005-570aecfae8e3?ixlib=rb-1.2.1&auto=format&fit=crop&w=3334&q=80",
            title: "LO MEJOR PARA TU BEBÉ"
        },
        {
            image: "https://images.unsplash.com/flagged/photo-1556746834-1cb5b8fabd54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=3304&q=80",
            title: "PONTE EN FORMA"
        },
    ];

    return  <GridList cellHeight={350} className={classes.gridList} cols={3}>
        {specialCategories.map(category => <GridListTile  key={category.title}>
            <img
                src={category.image}
                alt={category.title}
            />
            <GridListTileBar
                title={category.title}
                classes={{
                root: classes.titleBar,
                title: classes.title,
                }}
            />
            </GridListTile>
            )}
    </GridList>
}
