import React from "react";
import {Product} from "./model/Product";
import {createStyles, Divider, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import OfferCard from "./OfferCard";
import {LogoColors} from "./styles/Colors";

type OfferListProps = {
    title: string,
    products: Product[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        productContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 20,
            paddingTop: 20
        },
        gridList: {
            width: "80%",
            height: 600,
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        categoryTitle: {
            padding: theme.spacing(2),
            textAlign: 'left',
            fontFamily: 'Roboto',
            color: LogoColors.darkGreen1,
            fontSize: 16
        },
    }),
);

const OfferList = ({title, products}: OfferListProps) => {
    const classes = useStyles();

    return (
        <>
            <Typography className={classes.categoryTitle}>{title.toUpperCase()}</Typography>
            <Divider variant="middle"/>
            <div className={classes.productContainer}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                    spacing={4}
                >
                    {products.map(product => product && (
                        <Grid key={product._id} item xs={'auto'}>
                            <OfferCard product={product}/>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </>
    );
};

export default OfferList;
