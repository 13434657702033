import React, {ReactNode, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Product} from "./model/Product";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {slugifyProduct} from "./instrumentation/Slug";
import {LogoColors} from "./styles/Colors";
import {priceToText} from "./instrumentation/Numeric";
import InternalLink from "./InternalLink";
import {Button, CardActions} from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        paddingBottom: 0,
        cursor: 'hand',
    },
    image: {
        width: 300,
        height: 300,
        margin: "auto",
        display: "block",
    },
    productTitle: {
        height: 25,
        fontSize: 14,
        textAlign: 'center',
        paddingBottom: 0,
    },
    actions: {
        background: LogoColors.darkGreen1,
        justifyContent: 'center',
        cursor: 'hand',
    },
    nameContainer: {
        textAlign: 'center',
        paddingTop: 0,
        paddingBottom: 0,
    },
    priceContainer: {
        textAlign: 'center',
        paddingBottom: 0,
        height: 60,
    },
    price: {
        fontSize: 18,
    },
    originalPrice: {
        fontSize: 12,
        textDecoration: "line-through",
        color: LogoColors.red,
    },
    link: {
        textDecoration: "none",
        color: LogoColors.darkGreen1
    }
});

export type ProductCardProps = {
    product: Product
}

const ProductCard = ({product}: ProductCardProps) => {
    const classes = useStyles();
    const [active, setActive] = useState(false);

    const LinkToProduct = ({children}: { children: ReactNode }) =>
        <InternalLink
            className={classes.link}
            to={{
                pathname: `/productos/${slugifyProduct(product)}`,
            }}>
            {children}
        </InternalLink>;

    return (
        <div onMouseOver={() => setActive(true)} onMouseLeave={() => setActive(false)}>
            <Card
                className={classes.root}
                elevation={active ? 1 : 0}
            >
                <CardActionArea>
                    <LinkToProduct>
                        <CardMedia
                            className={classes.image}
                            component="img"
                            alt={product.nombre}
                            height="auto"
                            image={product.imagen}
                            title={product.nombre}
                        />
                    </LinkToProduct>
                </CardActionArea>
                <CardActions className={classes.actions}>
                    <Button size="small" fullWidth={true} color="secondary"
                            className="snipcart-add-item"
                            data-item-id={product._id}
                            data-item-price={(product.precioOferta || product.precio)?.toFixed(2)}
                            data-item-image={product.imagen}
                            data-item-name={product.nombre}
                            data-item-description={product.descripcion}
                            data-item-url={`https://cartagena121.netlify.app/productos/${slugifyProduct(product)}`}
                            data-items-categories={product.categorias ? product.categorias.map(c => c.nombre).join('|') : ''}>
                        <ShoppingCartIcon/>
                        Añadir a la cesta
                    </Button>
                </CardActions>
                <CardContent className={classes.priceContainer}>
                    <LinkToProduct>
                        <Typography className={classes.price} component="p">
                            {priceToText(product.precioOferta || product.precio)}
                        </Typography>
                        {product.precioOferta && <Typography className={classes.originalPrice} component="p">
                            {priceToText(product.precio)}
                        </Typography>}
                    </LinkToProduct>
                </CardContent>
                <CardContent className={classes.nameContainer}>
                    <LinkToProduct>
                        <Typography
                            style={active ? { textDecoration: 'underline'}: {}}
                            className={classes.productTitle}
                            gutterBottom variant="h5" component="h2">
                            {product.nombre}
                        </Typography>
                    </LinkToProduct>
                </CardContent>
            </Card>
        </div>
    );
};

export default ProductCard;
