import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from './logo.png';

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        logo: {
            width: 180,
            paddingRight: 30,
            cursor: 'pointer'
        },
        appBar: {
            zIndex: 999999,
        },
    }),
);

export default function MainMenu() {
    const classes = useStyles();
    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <>
            <div className={classes.grow}>
                <AppBar className={classes.appBar} position="fixed" elevation={0}>
                    <Toolbar>
                        <img src={logo} className={classes.logo} alt="logo" onClick={scrollTop}/>
                        <div className={classes.grow}/>
                    </Toolbar>
                </AppBar>
            </div>
        </>
    );
}
