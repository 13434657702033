import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import {Badge, Theme} from "@material-ui/core";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        root: {
            fontSize: 15,
        },
    }));

const CartIcon = () => {
    const classes = useStyles();
    return <Badge
        className={classes.root}
        color="secondary" >
            <ShoppingCartIcon/>
            <span className="snipcart-items-count"/>
    </Badge>;
};

export default CartIcon;
