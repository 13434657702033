const range = (initial:number, finalIncluded:number) => {
    let index = initial;
    let result = [];
     while (index <= finalIncluded) {
        result.push(index);
        index++;
    }
    return result;
};

const priceToText = (price?:number) => {
    if(!price) {
        return "Precio no disponible";
    }

    return `${price.toFixed(2)} €`;
};

export { range, priceToText }
