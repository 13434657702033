import React, {useState} from "react";
import InputBase from "@material-ui/core/InputBase";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {extractUrlFromSearchTerms} from "./instrumentation/Slug";
import SearchIcon from "@material-ui/icons/Search";
import {Box} from "@material-ui/core";
import {useRouting} from "./routing/Hook";
import {LogoColors} from "./styles/Colors";

type SearchInputProps = {
    onActivated: () => void
}

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        searchIcon: {
            display: 'flex',
            alignItems: 'center'
        },
        inputRoot: {
            color: 'inherit',
        },
        inputInput: {
            color: LogoColors.darkGreen1
        },
    }),
);

const SearchInput = ({onActivated}: SearchInputProps) => {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
    const routeManager = useRouting();
    const search = () => routeManager.navigateTo(extractUrlFromSearchTerms(searchTerm));
    const onKeyPressed = (ev: any) => {
        if (ev.key === 'Enter') {
            search();
            ev.preventDefault();
        }
    };

    const activate = () => {
        if(onActivated) {
            onActivated();
        }
    };
    return <>
        <Box className={classes.searchIcon} onClick={activate}>
            <SearchIcon onClick={search} type="submit" color="secondary"/>
            <InputBase
                color="primary"
                placeholder="Buscar…"
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{'aria-label': 'search'}}
                onKeyPress={onKeyPressed}
                onChange={(event) => setSearchTerm(event.target.value)}
            />
        </Box>
    </>
};

export default SearchInput;
