import {Feature} from "./Feature";

export class FeatureChecker {
    constructor(private flagsmith: Flagsmith) {}

    hasFeature(featureName: Feature) {
        return this.flagsmith.hasFeature(featureName)
    }
}

export type Flagsmith = {
    hasFeature: (featureName: string) => boolean
}


