import {Box, Divider, Grid, Link, Theme} from "@material-ui/core";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import logo from "./logo.png";
import {lightGray, LogoColors} from "./styles/Colors";

export default function Footer() {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                background: lightGray,
                paddingTop: 10,
            },
            paper: {
                padding: theme.spacing(2),
                textAlign: 'left',
                color:"secondary"
            },
            title: {
                margin:0
            },
            listItem: {
                fontFamily: 'Optima',
                color: LogoColors.darkGreen1,
                fontSize: 14
            },
            divider: {
                marginBottom: 10,
                marginTop: 10,
                maxWidth: 280
            },
            social: {
                paddingLeft: 20,
                paddingRight: 20,
                color: 'black',
            }
        }));

  //  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

    const classes = useStyles();
    return <Grid className={classes.root} container spacing={2}>
        {/*<Grid item xs={3}/>*/}
        {/*<Grid item xs={3}>*/}
        {/*    <Box className={classes.paper}>*/}
        {/*        <h6 className={classes.title}>INFORMACIÓN</h6>*/}
        {/*        <Divider className={classes.divider}/>*/}
        {/*        <Box>*/}
        {/*            <Link className={classes.listItem} href="#" onClick={preventDefault}>*/}
        {/*            Ayuda*/}
        {/*            </Link>*/}
        {/*        </Box>*/}
        {/*        <Box>*/}
        {/*            <Link className={classes.listItem} href="#" onClick={preventDefault}>*/}
        {/*                Tiempo estimado de envío*/}
        {/*            </Link>*/}
        {/*        </Box>*/}
        {/*        <Box>*/}
        {/*            <Link className={classes.listItem} href="#" onClick={preventDefault}>*/}
        {/*                Cambios y devoluciones*/}
        {/*            </Link>*/}
        {/*        </Box>*/}
        {/*    </Box>*/}
        {/*</Grid>*/}
        <Grid item xs={6}>
            <Box className={classes.paper}>
                <h6 className={classes.title}>CONTACTO</h6>
                <Divider className={classes.divider}/>
                <Box>
                    <Link className={classes.listItem} href="https://goo.gl/maps/Ccygh4FVh63VvhYy7">
                        C/ Cartagena 121, Madrid
                    </Link>
                </Box>
                <Box>
                    <Link className={classes.listItem} href="tel:915631494">
                        915631494
                    </Link>
                </Box>
            </Box>
        </Grid>
        <Grid item xs={6}>
            <Box className={classes.paper}>
                <h6 className={classes.title}>HORARIO</h6>
                <Divider className={classes.divider}/>
                <Box>
                    <Link className={classes.listItem}>
                        Lunes a Viernes de 9:30 a 13:45 y 17 a 20
                    </Link>
                </Box>
                <Box>
                    <Link className={classes.listItem}>
                        Sábados de 10 a 13:45
                    </Link>
                </Box>
            </Box>
        </Grid>
        {/*<Grid item xs={1}/>*/}
        {/*<Grid item xs={12}>*/}
        {/*    <Box display="flex">*/}
        {/*        <Box m="auto">*/}
        {/*                <InstagramIcon className={classes.social}/>*/}
        {/*                <FacebookIcon className={classes.social}/>*/}
        {/*                <TwitterIcon className={classes.social}/>*/}
        {/*        </Box>*/}
        {/*    </Box>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
            <Box display="flex">
                <Box m="auto">
                    <img src={logo} alt="logo" width="140px"/>
                </Box>
            </Box>
        </Grid>
    </Grid>
}
