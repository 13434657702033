export const arrayOrEmpty = <TItem>(array: (TItem[] | undefined)): TItem[] => array || [];

export const groupBy = <TItem, TKey>(array: TItem[], keyGetter: (itm: TItem) => TKey): Map<TKey, TItem[]> => {
    const map = new Map<TKey, TItem[]>();
    array.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}
