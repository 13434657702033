export class SnipcartManager {
    private div: any;

    setDivReference = (div: any) => {
        this.div = div;
    };

    isInitialised = () => this.div && this.div.current;

    isVisible = () => this.div.current.style.display !== 'none';

    show = () => {
        if(!this.isInitialised()) return;

        this.div.current.style.display = 'block'
    };

    hide = () => {
        if(!this.isInitialised()) return;

        this.div.current.style.display = 'none'
    };

    toggle = () => {
        if(!this.isInitialised()) return;

        if(this.isVisible()) {
           this.show();
        }
        else {
            this.hide();
        }
    };
}
