import React, {useEffect, useState} from "react";
import {useSanity} from "./sanity/Hook";
import {Product} from "./model/Product";
import {groupBy} from "./instrumentation/Array";
import {CategoryLevel2} from "./model/CategoryLevel2";
import OfferList from "./OfferList";
import TemporalOffers from "./TemporalOffers";

const Offers = () => {
    const sanity = useSanity();
    const [products, setProducts] = useState(new Map<CategoryLevel2, Product[]>());
    const [categoriesLevel2, setCategoriesLevel2] = useState([] as CategoryLevel2[]);

    useEffect(() => {
        if (categoriesLevel2 === []) return;

        const defaultCategory = {_id: "default-id", nombre: "Sin categoría"} as CategoryLevel2;

        const getCategoryLevel2 = (product: Product) => {
            if (!product.categorias || product.categorias.length === 0) return defaultCategory;

            return categoriesLevel2
                    .find(level2 => level2.subcategorias?.find(level3 => level3._id === product.categorias[0]._id))
                || defaultCategory
        };

        sanity.getOffers().then(offers => {
            setProducts(groupBy(offers.map(offer => offer.producto), product => getCategoryLevel2(product)));
        })
    }, [sanity, categoriesLevel2]);

    useEffect(() => {
        sanity.getCategoriesLevel2().then(categories => setCategoriesLevel2(categories))
    }, [sanity]);

    return <>
        <TemporalOffers/>
        {Array.from(products.keys()).filter(categoryLevel1 => !categoryLevel1.nombre.toUpperCase().includes("NAVIDAD")).map(categoryLevel1 => (
            <OfferList key={categoryLevel1._id} title={categoryLevel1.nombre} products={products.get(categoryLevel1) || []}/>
        ))}
    </>
};

export default Offers;
