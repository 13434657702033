import {Link, LinkProps} from "react-router-dom";
import React from "react";
import * as H from "history";
import {useSnipcart} from "./snipcart/Hook";

type InternalLinkProps<S extends any = H.LocationState> =
    React.PropsWithoutRef<LinkProps<S>>
    & React.RefAttributes<HTMLAnchorElement>;

const InternalLink =
    <S extends any = H.LocationState>(props: InternalLinkProps<S>) => {
        const snipcart = useSnipcart();
        const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
            if(props.onClick) {
                props.onClick(event);
            }
            snipcart.hide();
        };
        return <Link {...props} onClick={(event) => onClick(event)}/>;
    };

export default InternalLink;
