import {Product} from "../model/Product";

const slugify = (text: string) => {
    return text
        .trim()
        .toLowerCase()
        .replace('á', 'a')
        .replace('é', 'e')
        .replace('í', 'i')
        .replace('ó', 'o')
        .replace('ú', 'u')
        .replace('ü', 'u')
        .replace('ñ', 'n')
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
};

const extractCategorySlug = (pathname: string) => pathname.replace('/categorias/', '');

const productSlugSeparator = '__';

const slugifyProduct = (product: Product) => {
    return `${product._id}__${slugify(product.nombre)}`
};

const extractProductId = (path: string) => {
    path = path.replace('/productos/','');
    return path.substring(0, path.indexOf(productSlugSeparator));
};

const extractSearchTermsFromQueryParameters = (query: string) => {
    return query.substring(1);
};
const extractUrlFromSearchTerms = (searchTerm?: string) => {
    return `/buscar?${searchTerm}`;
};

export {
    slugify,
    slugifyProduct,
    extractCategorySlug,
    extractProductId,
    extractSearchTermsFromQueryParameters,
    extractUrlFromSearchTerms,
}
