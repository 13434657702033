import {createStyles, Divider, Drawer, Grid, Theme} from "@material-ui/core";
import React from "react";
import CategoryBox from "./CategoryBox";
import {makeStyles} from "@material-ui/core/styles";
import {CategoryLevel2} from "./model/CategoryLevel2";
import {cream} from "./styles/Colors";

const drawerWidth = "100%";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        drawerContainer: {
            overflow: 'auto',
        },
        content: {
            flexGrow: 1,
            paddingTop: 64,
            background: cream
        },
    }),
);

type FarmaDrawerProps = {
    categoriesLevel2: CategoryLevel2[]
    drawerOpen: boolean,
    setDrawerOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const FarmaDrawer = ({categoriesLevel2, drawerOpen, setDrawerOpen}: FarmaDrawerProps) => {
    const classes = useStyles();

    return <Drawer   className={classes.drawer}
                     classes={{
                         paper: classes.drawerPaper,
                     }}
        open={drawerOpen} onClose={() => setDrawerOpen(false)} anchor="top">
        <div className={classes.content}>
            <Divider/>
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
            >
                {categoriesLevel2?.map(category => (
                    <Grid key={category._id} item xs={'auto'}>
                        <CategoryBox category={category} setDrawerOpen={setDrawerOpen}/>
                    </Grid>
                ))}

            </Grid>
        </div>
    </Drawer>
};

export default FarmaDrawer;
