import React, {ReactNode, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import {Product} from "./model/Product";
import {darkGray, LogoColors} from "./styles/Colors";
import {priceToText} from "./instrumentation/Numeric";
import {Container} from '@material-ui/core';
import CategoryChip from "./CategoryChip";

const useStyles = makeStyles({
    root: {
        paddingBottom: 0,
        cursor: 'default',
    },
    image: {
        width: 300,
        height: 300,
        margin: "auto",
        display: "block",
        cursor: 'default',
    },
    productTitle: {
        minHeight: 25,
        fontSize: 14,
        textAlign: 'center',
        paddingBottom: 0,
    },
    productDescription: {
        paddingBottom: "10px",
        minHeight: 25,
        fontSize: 13,
        textAlign: 'center',
        width: "100%",
        color: darkGray,
        fontStyle: "italic"
    },
    actions: {
        background: LogoColors.darkGreen1,
        justifyContent: 'center',
    },
    nameContainer: {
        textAlign: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        maxWidth: 300
    },
    priceContainer: {
        textAlign: 'center',
        paddingBottom: 0,
        height: 60,
    },
    price: {
        fontSize: 18,
    },
    originalPrice: {
        fontSize: 12,
        textDecoration: "line-through",
        color: LogoColors.red,
    },
    link: {
        textDecoration: "none",
        color: LogoColors.darkGreen1
    },
    categories: {
        width: "100%",
    }
});

export type OfferCardProps = {
    product: Product
}

const OfferCard = ({product}: OfferCardProps) => {
    const classes = useStyles();
    const [active, setActive] = useState(false);

    const LinkToProduct = ({children}: { children: ReactNode }) =>
        <div className={classes.link}>
            {children}
        </div>;

    return (
        <div onMouseOver={() => setActive(true)} onMouseLeave={() => setActive(false)}>
            <Card
                className={classes.root}
                elevation={active ? 1 : 0}
            >
                <CardActionArea>
                    <LinkToProduct>
                        <CardMedia
                            className={classes.image}
                            component="img"
                            alt={product.nombre}
                            height="auto"
                            image={product.imagen}
                            title={product.nombre}
                        />
                    </LinkToProduct>
                </CardActionArea>
                <CardContent className={classes.priceContainer}>
                    <LinkToProduct>
                        <Typography className={classes.price} component="p">
                            {priceToText(product.precioOferta || product.precio)}
                        </Typography>
                        {product.precioOferta && <Typography className={classes.originalPrice} component="p">
                            {priceToText(product.precio)}
                        </Typography>}
                    </LinkToProduct>
                </CardContent>
                <CardContent className={classes.nameContainer}>
                    <LinkToProduct>
                        <Typography
                            style={active ? {textDecoration: 'underline'} : {}}
                            className={classes.productTitle}
                            gutterBottom variant="h5" component="h2">
                            {product.nombre}
                        </Typography>
                        <Typography
                            className={classes.productDescription}
                        >
                            {product.descripcion || "-"}
                        </Typography>
                    </LinkToProduct>
                    <Container className={classes.categories}>
                        {product.categorias.map(categoria => <CategoryChip key={categoria._id} category={categoria}/>)}
                    </Container>
                </CardContent>
            </Card>
        </div>
    );
};

export default OfferCard;
