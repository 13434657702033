import {SanityClient} from "@sanity/client";
import {CategoryLevel1} from "../model/CategoryLevel1";
import {SanityApi, SearchResult} from "./SanityApi";
import {CategoryLevel3} from "../model/CategoryLevel3";
import {Product} from "../model/Product";
import {CategoryPath} from "../model/CategoryPath";
import {findCategoryPath} from "../instrumentation/Category";
import imageUrlBuilder from '@sanity/image-url'
import {ImageUrlBuilder} from "@sanity/image-url/lib/types/builder";
import {ProductoSanity} from "../sanityModel/ProductoSanity";
import {Offer} from "../model/Offer";
import {OfferSanity} from "../sanityModel/OfferSanity";
import {CategoryLevel2} from "../model/CategoryLevel2";
import {TemporalOffer} from "../model/TemporalOffer";
import {TemporalOfferSanity} from "../sanityModel/TemporalOfferSanity";

export class RealSanityApi implements SanityApi {
    private readonly productDetailFields =
        `_id, 
         nombre,
         categorias[]-> {
             _id,
             nombre
         },
         variantePorDefecto`;

    private urlBuilder: ImageUrlBuilder;

    constructor(private sanityClient: SanityClient) {
        this.urlBuilder = imageUrlBuilder(sanityClient)
    }

    getCategoriesLevel1(): Promise<CategoryLevel1[]> {
        const query =
            ` *[_type == "categoriaNivel1"] {
            _id,
            nombre,
            subcategorias[]-> {
            _id,
            nombre,
            subcategorias[]-> {
                _id,
                nombre
                }
            }
        } | order(orden asc)`;
        return this.sanityClient.fetch(query, {})
    }

    getCategoriesLevel2(): Promise<CategoryLevel2[]> {
        const query =
            ` *[_type == "categoriaNivel2"] {
            _id,
            nombre,
            subcategorias[]-> {
            _id,
            nombre
            }
        } `;
        return this.sanityClient.fetch(query, {})
    }

    getCategoryPath(slug: string): Promise<CategoryPath | undefined> {
        const getCategory = async () => {
            const categoriesLevel1 = await this.getCategoriesLevel1();
            return findCategoryPath(categoriesLevel1, slug);
        };

        return getCategory();
    }

    searchProducts(searchTerm: string): Promise<SearchResult> {
        const getAsync = async () => {
            let parameters = {
                t0: searchTerm
            };
            const query =
                `*[_type == "producto"
                    && (_id match $t0
                    ||_type match $t0
                    ||nombre match $t0
                    ||descripcion match $t0
                    ||variantePorDefecto.nombre match $t0
                    ||variantes[].nombre match $t0
                    ||references(*[_type=="categoriaNivel3" && nombre match $t0]._id)
                    ||body.es[].style match $t0
                    ||body.es[].list match $t0)]{
                    ${this.productDetailFields}
                 }`;
            const sanityProducts = await this.sanityClient.fetch<ProductoSanity[]>(query, parameters);
            return {
                validSearchTerm: true,
                products: sanityProducts.map(this.mapToProduct)
            } as SearchResult
        };

        return getAsync();
    }

    getProductsByCategoryLevel3(categoryLevel3: CategoryLevel3): Promise<Product[]> {
        const getAsync = async () => {
            let parameters = {
                categoriaNivel3Id: categoryLevel3._id
            };
            const query =
                `*[_type == "producto" && references($categoriaNivel3Id)] | order(nombre asc) {
                    ${this.productDetailFields}
                 }`;
            const sanityProducts = await this.sanityClient.fetch<ProductoSanity[]>(query, parameters);
            return sanityProducts.map(this.mapToProduct);
        };

        return getAsync();
    }

    getProduct(productId: string): Promise<Product | undefined> {
        const getAsync = async () => {
            let parameters = {
                productId
            };
            const query =
                `*[_type == "producto" && _id == $productId] {
                _id, 
                nombre,
                descripcion,
                categorias[]-> {
                    _id,
                    nombre,
                },
                variantePorDefecto
             }`;
            const products = await this.sanityClient.fetch<ProductoSanity[]>(query, parameters) || [];

            return products && products.length > 0 ? this.mapToProduct(products[0]) : undefined;
        };

        return getAsync();
    }

    getOffers(): Promise<Offer[]> {
        const getAsync = async () => {
            const query =
                `*[_type == "oferta"]{ _id, producto->{
                _id,
                nombre, 
                descripcion,
                variantePorDefecto
                {
                    _id,
                    imagenes,
                    precio, 
                    precioOferta
                 },
                 categorias[]-> {
                    _id,
                    nombre,
                },
                 }} | order(producto.nombre asc)`;
            const offers = await this.sanityClient.fetch<OfferSanity[]>(query) || [];

            return offers ? offers.map(this.mapToOffer) : [];
        };

        return getAsync();
    }

    getTemporalOffers(): Promise<TemporalOffer[]> {
        const getAsync = async () => {
            const query =
                `*[_type == "ofertaTemporal"]{ _id, 
                nombre, 
                descripcion,
                urlImagen,
                urlImagenMovil,
                tamanoTexto,
                tamanoTextoMovil,
                productos[]->{
                _id, 
                nombre, 
                descripcion,
                variantePorDefecto
                {
                    imagenes,
                    precio, 
                    precioOferta
                 },
                 categorias[]-> {
                    _id,
                    nombre,
                },
                 }} | order(nombre asc)`;
            const offers = await this.sanityClient.fetch<TemporalOfferSanity[]>(query) || [];

            return offers ? offers.map(this.mapToTemporalOffer) : [];
        };

        return getAsync();
    }

    private mapToImage = (sanityProduct: ProductoSanity) => {
        const variante = sanityProduct.variantePorDefecto;
        if (!variante || !variante.imagenes || variante.imagenes.length === 0) {
            return `https://via.placeholder.com/300x200.png?text=Imagen+no+disponible`
        }

        return this.urlBuilder.image(variante.imagenes[0].asset).width(600).url()
    };

    private mapToPrice = (sanityProduct: ProductoSanity) => {
        const variante = sanityProduct.variantePorDefecto;
        return !variante || !variante.precio ? undefined : variante.precio;
    };

    private mapToOfferPrice = (sanityProduct: ProductoSanity) => {
        const variante = sanityProduct.variantePorDefecto;
        return !variante || !variante.precioOferta ? undefined : variante.precioOferta;
    };

    private mapToProduct = (sanityProduct: ProductoSanity) =>
        ({
            _id: sanityProduct._id,
            nombre: sanityProduct.nombre,
            descripcion: sanityProduct.descripcion,
            imagen: this.mapToImage(sanityProduct),
            precio: this.mapToPrice(sanityProduct),
            precioOferta: this.mapToOfferPrice(sanityProduct),
            laboratorio: "Normon",
            categorias: sanityProduct.categorias
        } as Product)

    private mapToOffer = (sanityOffer: OfferSanity) =>
        ({
            _id: sanityOffer._id,
            producto: this.mapToProduct(sanityOffer.producto)
        } as Offer)

    private mapToTemporalOffer = (sanityOffer: TemporalOfferSanity) =>
        ({
            _id: sanityOffer._id,
            nombre: sanityOffer.nombre,
            descripcion: sanityOffer.descripcion,
            urlImagen: sanityOffer.urlImagen,
            urlImagenMovil: sanityOffer.urlImagenMovil,
            tamanoTexto: sanityOffer.tamanoTexto,
            tamanoTextoMovil: sanityOffer.tamanoTextoMovil,
            productos: sanityOffer.productos.map(this.mapToProduct)
        } as TemporalOffer)

}
