import React from 'react';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import MainMenuOffers from "./MainMenuOffers";
import Footer from "./Footer";
// @ts-ignore
import flagsmith from 'flagsmith'
import {FeatureChecker} from "./feature-flags/FeatureChecker";
import {FeatureContext} from './feature-flags/Context';
import {Router} from "react-router-dom";
import {createBrowserHistory} from "history";
import Content from "./Content";
import createSanityClient from "./sanity/SanityClient";
import {SanityContext} from './sanity/Context';
import {RealSanityApi} from "./sanity/RealSanityApi";
import {FakeSanityApi} from "./sanity/FakeSanityApi";
import {Feature} from "./feature-flags/Feature";
import {CachedSanityApi} from "./sanity/CachedSanityApi";
import {cream, gray, LogoColors} from "./styles/Colors";
import {Snipcart} from "./Snipcart";
import {SnipcartContext} from './snipcart/Context';
import {SnipcartManager} from "./snipcart/SnipcartManager";
import {RoutingContext} from './routing/Context';
import {RouteManager} from "./routing/RouteManager";
import MainMenu from "./MainMenu";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: cream,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
        light: '#fce4ec',
        main: LogoColors.darkGreen1,
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#27ff21',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    text: {
        primary: LogoColors.darkGreen1
    }
  },
});

export default function App() {
    document.body.style.overflowX = "hidden";
    document.body.style.background = gray;
    const featureChecker = new FeatureChecker(flagsmith);
    const innerSanityApi = featureChecker.hasFeature(Feature.ShowFakeData) ?
        new FakeSanityApi() :
        new RealSanityApi(createSanityClient());
    const sanityApi = new CachedSanityApi(innerSanityApi);
    const snipcartManager = new SnipcartManager();
    const routerManager = new RouteManager(createBrowserHistory());

    return (

      <FeatureContext.Provider value={featureChecker}>
          <SnipcartContext.Provider value={snipcartManager}>
              <SanityContext.Provider value={sanityApi}>
                  <RoutingContext.Provider value={routerManager}>
                      <ThemeProvider theme={theme}>
                          <Router history={routerManager.history}>
                              {featureChecker.hasFeature(Feature.ShowOnlyOffers) ? <MainMenuOffers/> : <MainMenu/>}
                              <Snipcart/>
                              <Content/>
                          </Router>
                          <Footer/>
                      </ThemeProvider>
                  </RoutingContext.Provider>
              </SanityContext.Provider>
          </SnipcartContext.Provider>
      </FeatureContext.Provider>
  );
}
