import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Chip, Tooltip} from '@material-ui/core';

const CHIP_MAX_WIDTH = 250;
const CHIP_ICON_WIDTH = 30;

const EllipsisText = (props: any) => {
    const {children} = props

    return (
        <div style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: CHIP_MAX_WIDTH - CHIP_ICON_WIDTH
        }}>
            {children}
        </div>
    )
}

const useStyles = makeStyles({
    category: {
        marginLeft: "2px",
        marginRight: "2px",
        marginBottom: "2px"
    }
});

export type CategoryChipProps = {
    category: { _id: string, nombre: string }
}

const CategoryChip = ({category}: CategoryChipProps) => {
    const classes = useStyles();

    return <Tooltip key={category._id} title={category.nombre}>
        <Chip key={category._id}
              label={
                  <EllipsisText>{category.nombre}</EllipsisText>}
              className={classes.category}
              variant="outlined" color="secondary"/>
    </Tooltip>;

};

export default CategoryChip;
