import SanityClientConstructor, {ClientConfig} from '@sanity/client'

const createSanityClient = () => {
    return SanityClientConstructor({
        projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
        dataset: process.env.REACT_APP_SANITY_DATASET,
        token: process.env.REACT_APP_SANITY_READ_ONLY_TOKEN,
        useCdn: true
    } as ClientConfig);
};

export default createSanityClient;
