import React from "react";
import {Product} from "./model/Product";
import {createStyles, Grid, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ProductCard from "./ProductCard";

type ProductListProps = {
    products: Product[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            backgroundColor: theme.palette.background.paper,
            paddingLeft: 30,
            paddingRight: 30,
            paddingBottom: 20,
            paddingTop: 20
        },
        gridList: {
            width: "80%",
            height: 600,
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
    }),
);

const ProductList = ({products}: ProductListProps) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={4}
            >
                {products.map(product => product && (
                    <Grid key={product._id} item xs={'auto'}>
                        <ProductCard product={product}/>
                    </Grid>
                ))}
            </Grid>
        </div>
    );
};

export default ProductList;
