import React, {useEffect, useState} from "react";
import * as H from "history";
import {useSanity} from "./sanity/Hook";
import {Product} from "./model/Product";
import {CategoryPath} from "./model/CategoryPath";
import {extractCategorySlug} from "./instrumentation/Slug";
import ProductList from "./ProductList";

type CategoryProps = {
    location: H.Location<undefined>
}

const CategoryProducts = ({location}: CategoryProps) => {
    const categorySlug = extractCategorySlug(location.pathname);
    const sanity = useSanity();
    const [categoryPath, setCategoryPath] = useState< CategoryPath | undefined>(undefined);
    const [products, setProducts] = useState([] as Product[]);

    useEffect(() => {
        sanity.getCategoryPath(categorySlug).then(setCategoryPath)
    }, [sanity,categorySlug]);

    useEffect(() => {
        if(categoryPath) {
            sanity.getProductsByCategoryLevel3(categoryPath.level3).then(setProducts)
        }
    }, [sanity,categoryPath]);

    return <ProductList products={products}/>;
};

export default CategoryProducts;
