import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import {autoPlay} from 'react-swipeable-views-utils';
import {LogoColors} from "./styles/Colors";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
    {
        label: 'San Francisco – Oakland Bay Bridge, United States',
        imgPath:
            'https://www.lacerodontologia.com/wp-content/uploads/2018/07/gingilacer-pasta-slider.png',
    },
    {
        label: 'Bird',
        imgPath:
            'https://farmacialosaltos.es/99-large_default/gel-crema-toque-seco-spf-50-fotoprotector-isdin-50-ml.jpg',
    },
    {
        label: 'Bali, Indonesia',
        imgPath:
            'https://static.expanscience.com/sites/default/files/produits-mustela-seche-420_2.png',
    },
    {
        label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
        imgPath:
            'https://farmaciaalbir.com/845-thickbox_default/eucerin-antiedad-hyaluron-filler-noche-50-ml.jpg',
    },
    {
        label: 'Goč, Serbia',
        imgPath:
            'https://salud.bayer.es/static/img/content/products/redoxon/slider/redoxon-inmuno_4.2.png',
    },
    {
        label: 'Lacer ates',
        imgPath:
            'https://www.micochecito.es/8785/sebamed-baby-crema-bals-mica-50ml.jpg',
    },
];

const useStyles = makeStyles(_ => ({
    root: {
        flexGrow: 1,
    },
    slide: {
        color: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        display: 'flex',
        background: LogoColors.yellowGreen
    },
    img: {
        height: 350,
        display: 'block',
        overflow: 'hidden',
        opacity: 1
    },
}));

function FarmaStepper() {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <div className={classes.root}>
            <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                interval={5000}
                enableMouseEvents
            >
                {tutorialSteps.map((step, index) => (
                    <div className={classes.slide} key={step.label}>
                        {Math.abs(activeStep - index) <= 2 ? (
                            <img className={classes.img} src={step.imgPath} alt={step.label} />
                        ) : null}
                    </div>
                ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    </Button>
                }
            />
        </div>
    );
}

export default FarmaStepper;
