import {Box, Theme, Typography} from "@material-ui/core";
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {CategoryLevel2} from "./model/CategoryLevel2";
import {slugify} from "./instrumentation/Slug";
import {LogoColors} from "./styles/Colors";
import InternalLink from "./InternalLink";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: '#e4e4e4',
            paddingTop: 10,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'left',
            color:"secondary",
        },
        title: {
            margin:0 ,
            paddingBottom: 10,
            color: LogoColors.darkGreen1
        },
        listItem: {
            fontFamily: 'Optima',
            color: LogoColors.darkGreen1,
            fontSize: 14
        },
        divider: {
            marginBottom: 10,
            marginTop: 10,
            maxWidth: 280
        },
        social: {
            paddingLeft: 20,
            paddingRight: 20,
            color: 'black',
        },
        link: {
            textDecoration: "none"
        }
    }));

type CategoryProps = {
    category: CategoryLevel2,
    setDrawerOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
}

const CategoryBox = ({setDrawerOpen, category}: CategoryProps) => {
    const classes = useStyles();

    return (
        <Box className={classes.paper}>
            <h6 className={classes.title}>{category.nombre.toUpperCase()}</h6>
            {category.subcategorias?.map(subcategoria =>
                <Box key={subcategoria._id}>
                    <InternalLink
                        className={classes.link}
                        onClick={() => setDrawerOpen(false)}
                        to={{
                        pathname: `/categorias/${slugify(subcategoria.nombre)}`,
                    }}>
                        <Typography className={classes.listItem}>
                            {subcategoria.nombre}
                        </Typography>
                    </InternalLink>
            </Box>)}
        </Box>
    );
};

export default CategoryBox;
