import {CategoryLevel1} from "../model/CategoryLevel1";
import {slugify} from "./Slug";
import {CategoryPath} from "../model/CategoryPath";
import {arrayOrEmpty} from "./Array";

const findCategoryPath = (categoriesLevel1: CategoryLevel1[], slug: string): (CategoryPath | undefined )=> {
    for (const categoryLevel1 of categoriesLevel1) {
        for (const categoryLevel2 of arrayOrEmpty(categoryLevel1.subcategorias)) {
            const categoryLevel3 = arrayOrEmpty(categoryLevel2.subcategorias).find(categoriaNivel3 =>
                slugify(categoriaNivel3.nombre) === slug
            );

            if (categoryLevel3) {
                return {
                    level1: categoryLevel1,
                    level2: categoryLevel2,
                    level3: categoryLevel3
                } as CategoryPath
            }
        }
    }

    return undefined
};

export {findCategoryPath}
