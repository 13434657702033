import React, {useEffect, useState} from "react";
import * as H from "history";
import {useSanity} from "./sanity/Hook";
import {Product} from "./model/Product";
import {extractSearchTermsFromQueryParameters} from "./instrumentation/Slug";
import ProductList from "./ProductList";

type CategoryProps = {
    location: H.Location<undefined>
}

const SearchProducts = ({location}: CategoryProps) => {
    const searchTerms = extractSearchTermsFromQueryParameters(location.search);
    const sanity = useSanity();
    const [products, setProducts] = useState([] as Product[]);
    useEffect(() => {
        if(searchTerms) {
            sanity.searchProducts(searchTerms).then((result) => setProducts(result.products || []))
        }
    }, [sanity,searchTerms]);

    return <ProductList products={products}/>;
};

export default SearchProducts;
