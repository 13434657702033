import {CategoryLevel1} from "../model/CategoryLevel1";
import {SanityApi, SearchResult} from "./SanityApi";
import {CategoryLevel3} from "../model/CategoryLevel3";
import {Product} from "../model/Product";
import {range} from "../instrumentation/Numeric";
import {CategoryPath} from "../model/CategoryPath";
import {findCategoryPath} from "../instrumentation/Category";
import {CategoryLevel2} from "../model/CategoryLevel2";
import {Offer} from "../model/Offer";
import {TemporalOffer} from "../model/TemporalOffer";

export class FakeSanityApi implements SanityApi {
    searchProducts(searchTerm: string): Promise<SearchResult> {
        return Promise.resolve({
            validSearchTerm: true,
            products: this.productsWithImageAndPrice
        } as SearchResult);
    }

    getCategoriesLevel1(): Promise<CategoryLevel1[]> {
        return Promise.resolve([
            {
                "_id": "5baaad34-a2ac-4dc9-a8f0-c99c86fcefd3",
                "nombre": "Corporal *",
                "subcategorias": [
                    {
                        "_id": "f72f924a-5d79-4e59-a855-e319c0964c93",
                        "nombre": "Salud Bucodental *",
                        "subcategorias": [
                            {
                                "_id": "caccfd0a-5aae-4579-a6a7-69ae2bbb01b9",
                                "nombre": "Cepillos dentales *"
                            },
                            {
                                "_id": "37509127-f362-4143-b54c-449bf1dbfd59",
                                "nombre": "Hilos y ceras dentales"
                            },
                            {
                                "_id": "50300f5d-3777-42af-9bd4-09d4ca013472",
                                "nombre": "Pastas dentales"
                            },
                            {
                                "_id": "30915929-f14b-4330-9281-97d00dfcb603",
                                "nombre": "Colutorios"
                            },
                            {
                                "_id": "3766a937-8116-4c9f-88ee-9cf9cda390e5",
                                "nombre": "Fijadores dentales"
                            },
                            {
                                "_id": "e2a647ee-0812-4f60-8849-c9bdd213eff4",
                                "nombre": "Irrigadores dentales"
                            },
                            {
                                "_id": "deb15518-cf35-405e-b1dd-1d50b784ee2a",
                                "nombre": "Otros (salud bucodental)"
                            }
                        ]
                    }
                ]
            },
            {
                "_id": "048ea549-1e15-4d28-90cf-a1c6ad01daec",
                "nombre": "Belleza"
            },
            {
                "_id": "a9510bd6-f2b1-4293-a485-cfcd7bed923e",
                "nombre": "Bebé y niños"
            }
        ] as CategoryLevel1[]);
    }


    getCategoryPath(slug: string): Promise<CategoryPath | undefined> {
        const getCategory = async () => {
            const categoriesLevel1 = await this.getCategoriesLevel1();
            return findCategoryPath(categoriesLevel1, slug);
        };

        return getCategory();
    }

    getProductsByCategoryLevel3(categoryLevel3: CategoryLevel3): Promise<Product[]> {
        return Promise.resolve(this.productsWithImageAndPrice);
    }

    private productsWithImageAndPrice = range(0,40).map( index =>
        ([  {
                _id: `2345-asdas-2eqcdsf-${3*index}`,
                nombre: "Arkobiotics Supraflor Intens",
                descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex e",
                laboratorio: "Arkopharma",
                precio: 12.3,
                imagen: "Arkobiotics-supraflor-intens-arkopharma.jpg",
                categorias: []
            },
            {
                _id: `2345-asdas-2eqcdsf-${3*index+1}`,
                nombre: "Argomega Aceite de Krill",
                descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex e",
                laboratorio: "Arkopharma",
                precio: 12.3,
                precioOferta: 9.99,
                imagen: "arkomega-aceite-de-krill.png",
                categorias: []
            },
            {
                _id: `2345-asdas-2eqcdsf-${3*index+2}`,
                nombre: "Pack Stop Piojos",
                descripcion: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex e",
                laboratorio: "Arkopharma",
                precio: 12.3,
                imagen: "pack-stop-piojos-kit-completo.jpg",
                categorias: []
            },
        ]) as Product[]).reduce((arg1, arg2) => [...arg1, ...arg2]);

    getProduct(productId: string): Promise<Product | undefined> {
        return Promise.resolve(this.productsWithImageAndPrice.find(product => product._id === productId));
    }

    getOffers(): Promise<Offer[]> {
        return Promise.resolve(this.productsWithImageAndPrice.map(product => ({_id: product._id, producto: product})));
    }

    getTemporalOffers(): Promise<TemporalOffer[]> {
        return Promise.resolve(
            [{
                _id: "id",
                nombre: "Ofertas de Verano",
                descripcion: "Pasa un verano sano",
                urlImagen: "https://images.unsplash.com/photo-1510267792726-cfde433d6609?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=8000&h=2000&fit=crop",
                urlImagenMovil: "https://images.unsplash.com/photo-1510267792726-cfde433d6609?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&h=1000&fit=crop",
                tamanoTexto: "2.5vw",
                tamanoTextoMovil: "5vw",
                productos: this.productsWithImageAndPrice.slice(0, 4)
            }]);
    }

    getCategoriesLevel2(): Promise<CategoryLevel2[]> {
        const getAsync = async () => (await this.getCategoriesLevel1()).flatMap(level1 => level1.subcategorias || []);

        return getAsync();
    }

}
