import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {white} from "./styles/Colors";
import Offers from "./Offers";
import Home from "./Home";
import SearchProducts from "./SearchProducts";
import ProductDetail from "./ProductDetail";
import CategoryProducts from "./CategoryProducts";
import {useFeatureChecker} from "./feature-flags/Hook";
import {Feature} from "./feature-flags/Feature";

const useStyles = makeStyles((_: Theme) =>
    createStyles({
        content: {
            flexGrow: 1,
            paddingTop: 64,
            minHeight: 600,
            background: white
        },
    }),
);

const Content = () => {

    const hasFeature = useFeatureChecker();
    const classes = useStyles();
    return <div className={classes.content}>

        {hasFeature(Feature.ShowOnlyOffers) ?
            (
                <Switch>
                    <Route exact path="/josemiguel" component={Offers}/>
                    <Redirect to="/ofertas"/>
                </Switch>) :
            (
                <Switch>
                    <Route exact path="/" component={Home}/>
                    <Route path='/categorias/:handle' component={CategoryProducts}/>
                    <Route path='/ofertas' component={Offers}/>
                    <Route path='/productos/:handle' component={ProductDetail}/>
                    <Route path='/buscar' component={SearchProducts}/>
                </Switch>)
        }

    </div>
};

export default Content;
